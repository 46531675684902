import ahoy from 'ahoy.js';
import Swiper, { Navigation, Pagination } from './vendor/swiper-bundle-8.0.6.min';
$(document).on('ready turbolinks:load', function() {
  // Track 'view map' clicks and add to database
  $(document).on('click', '.map_track_click', function () {
    ahoy.track(
      'Map view',
      { map_id: $(this).data('map-id'), map_name: $(this).data('map-name') }
    );
  });
});
$(this)

$(document).on('ready turbolinks:load', function() {
  var navToggle = document.getElementById('sidebarToggle')
  var sidebar = document.getElementById('sidebar')
  if (navToggle != undefined) {
    navToggle.addEventListener('click', function(e) {
      if (sidebar.classList.contains('sidebar-nav--active')) {
        sidebar.classList.remove('sidebar-nav--active');
      } else {
        sidebar.classList.add('sidebar-nav--active');
      }
    })
  }

  var dropdownToggle = document.getElementById('dropdownNavigationToggle')
  var dropdown = document.getElementById('dropdownNavigation')
  if (dropdownToggle != undefined) {
    dropdownToggle.addEventListener('click', function(e) {
      toggleDropdown(dropdown)
    })

    document.addEventListener('click', function(e) {
      if (!dropdown.contains(e.target) && dropdownActive(dropdown)) {
        toggleDropdown(dropdown)
      }
    })
  }

  function dropdownActive(dropdown) {
    return dropdown.classList.contains('dropdown-navigation--active')
  }

  function toggleDropdown(dropdown) {
    if (dropdownActive(dropdown)) {
      dropdown.classList.remove('dropdown-navigation--active')
    } else {
      dropdown.classList.add('dropdown-navigation--active')
    }
  }
})

// Open Tickets Group
var groupButton = $('.tickets__group__header');

groupButton.on('click', function() {
  var icon = $(this).find('.tickets__group__header__icon');
  var tickets = $(this).next('.tickets__group__content');
  var empty = $(this).hasClass('is-empty');

  if (!empty) {
    if (!$(this).find('.tickets__group__header__icon').hasClass('tickets__group__header__icon--active')) {
      clearActive(true);
    }

    icon.toggleClass('tickets__group__header__icon--active');
    tickets.toggleClass('tickets__group__content--active');
  }
})

// Open Tickets Details
$(document).on('ready turbolinks:load', function() {
  var detailsButton = $('.open-ticket-details');

  detailsButton.on('click', function() {
    var id = $(this).attr('data-tid');
    var details = $('.tickets__wrapper--details[data-tid='+ id +']');

    if (!$(this).hasClass('button--active')) {
      clearActive(false);
    }

    details.toggleClass('tickets__wrapper--active');
    $(this).toggleClass('button--active');
  })

  function clearActive(all) {
    var activeIcon = $('.tickets__group__header__icon--active');
    var activeTickets = $('.tickets__group__content--active');
    var activeDetailsButton = $('.open-ticket-details.button--active');
    var activeDetails = $('.tickets__wrapper--active');

    if (all) {
      activeIcon.removeClass('tickets__group__header__icon--active');
      activeTickets.removeClass('tickets__group__content--active');
    }

    activeDetailsButton.removeClass('button--active')
    activeDetails.removeClass('tickets__wrapper--active');
  }
})

$(document).on('ready turbolinks:load', function() {
  $('.alert__close').on('click', function() {
    $('.alert').animate({
      opacity: 0,
      top: '-=64'
    }, 500)
  });

  setTimeout(function() {
    $('.alert').animate({
      opacity: 1,
      right: '-=400'
    }, 500)
  }, 10000)
})


$(document).on('ready turbolinks:load', function() {
  var helpToggle = document.getElementById('helpToggle')
  var helpPanes = document.getElementById('helpPanes')
  var swiper = initHelpCarousel()

  if (helpToggle != undefined) {
    helpToggle.addEventListener('click', function(e) {
      if (helpPanes.classList.contains('active')) {
        helpPanes.classList.remove('active');
      } else {
        helpPanes.classList.add('active');
        swiper = initHelpCarousel()
      }
    })
    if(helpPanes != undefined) {
      helpPanes.addEventListener('click', function(e) {
        if (e.target.classList.contains('modal') && helpPanes.classList.contains('active')) {
          helpPanes.classList.remove('active');
        }
      })
    }
  }

  $(document).keyup(function(e) {
    if (e.key === "Escape") {
      if (helpPanes.classList.contains('active')) {
        helpPanes.classList.remove('active');
      }
    }
  });

  function initHelpCarousel() {
    var swiper = new Swiper(".modal-pane", {
      slidesPerView: 1,
      spaceBetween: 48,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    return swiper
  }
})
